.textAlign-center {
  text-align: center !important;
}

.text_small {
  font-size: 10px;
  opacity: 0.5;
}

.text_normal {
  font-size: 16px;
  font-family: Acre, sans-serif;
  color: #1B2437;
  text-decoration: none !important;
}

.text_big {
  font-size: 24px;
}

.textTransform_uppercase {
  text-transform: uppercase;
}

.fontSize_normalSmall {
  font-size: 15px !important;
}
