.boardsList {
  margin-bottom: 55px;
}

.boardsList-boards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.boardsList-title {
  margin-bottom: 0;
}

.boardsList-title-icon {
  margin-right: 8px;
}
