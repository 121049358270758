.tasks-files {
  display: flex;
  min-height: 0;
}

.tasks-files .files-block-content {
  min-height: 0;
}

.tasks-files .searchPanel-group {
  border: none;
}
