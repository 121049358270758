.m-r-5 {
  margin-right: 5px !important;
}

.m-r--15 {
  margin-right: -15px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-0 {
  margin: 0 !important;
}
