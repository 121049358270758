.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 10px;
}

.input, textarea {
  font-family: Acre, sans-serif;
  padding: 10px;
  border: 1px solid #e4e9ec;
  border-radius: 4px;
  width: 100%;
  outline: none;
  transition: border-color, box-shadow;
  transition-duration: .2s;
  appearance: textfield;
}

.input:focus, textarea:focus {
  border-color: #93ccee;
  box-shadow: 0 0 0 3px #d5ecfb;
}

.input_medium {
  padding: 5px 10px;
}

::placeholder {
  color: #bdbdbd;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bdbdbd;
}

::-ms-input-placeholder {
  color: #bdbdbd;
}
