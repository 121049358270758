.btn {
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  background: #fff;
  border-width: 0px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .025em;
  color: #6772e5;
  text-decoration: none;
  transition: all .15s ease;
  cursor: pointer;
  text-align: center;
}

.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
}

.btn_medium {
  height: 30px;
  line-height: 31px;
  padding: 0 12px;
}

.btn_medium_square {
  padding: 0 8px;
}

.btn_small {
  height: 20px;
  line-height: 20px;
  padding: 0 9px;
  font-size: 14px;
}

.btn_small_square {
  padding: 0 2px;
  width: 20px;
  height: 20px;
}

.btn-icon {
  font-size: 16px;
  margin-right: 5px;
}

.btn-icon:last-child {
  margin-right: 0;
}

.btn_small .btn-icon {
  font-size: 14px;
}

.btn_primary {
  color: #FFF;
  background: #7795F8;
}

.btn_primary:hover {
  background: #6772e5;
}

.btn_ok {
  color: #FFF;
  background: #3ecf8e;
}

.btn_transparent {
  background: transparent;
  box-shadow: none;
}

.btn_transparent:hover {
  box-shadow: none;
  transform: translateY(0px);
}

.btn_close {
  background: #EEE;
  color: #c60000;
}

.btn.disabled {
  cursor: default;
}
