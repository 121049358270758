.dropdown_wrapper {
  position: relative;
}

.dropdown {
  border-radius: 3px;
  padding: 8px 12px;
}

.dropdown-chevron {
  float: right;
}

.dropdown-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: 39px;
  border-radius: 4px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
  background-color: #FFF;
  z-index: 100000;
}

.dropdown-dropdown-item {
  padding: 7px 10px;
  border-bottom: solid 1px #EEE;
  cursor: pointer;
}
