.learnings-block {
  flex: 1;
  margin-bottom: 25px;
}

.learnings-block-content {
  padding: 0;
  margin: 15px 0;
  min-height: 250px;
  position: relative;
  overflow: visible;
}

.learnings-block-content-line:hover .learnings-block-btnTrash {
  display: block;
}

.learnings-block-content-line {
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  border-bottom: solid 1px #EBEDF0;
  align-items: center;
  position: relative;
  min-height: 50px;
}

.learnings-block-content-line-icon {
  margin-right: 10px;
}

.learnings-block-content-line-text {
  flex: 1;
}

.learnings-block-content-line-icon, .learnings-block-content-line-cta {
  font-size: 20px;
}

.learnings-block-form {
  background: #FFF;
}

.learnings-block-btnTrash {
  position: absolute;
  top: 7px;
  right: -10px;
  display: none;
}

.learnings-title-icon {
  margin-right: 10px;
}
