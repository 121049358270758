.user-form {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: #F3F7FA;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-form .form-title {
  margin: 0 auto 20px;
  width: 80%;
  text-align: center;
}

.center-square {
  margin: 0;
  padding: 30px 0 40px;
  max-width: 580px;
  width: 100%;
  position: absolute;
  background-color: white;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.user-email-input {
  width: 80%;
}

.user-password-input {
  width: 80%;
}

.user-submit-button {
  min-width: 80px;
  border-width: 0;
}

.user-logo {
  font-size: 64px;
  margin-bottom: 40px;
}

@media(max-width: 991px) {
  .center-square {
    max-width: none;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
}
