.tabs {
  display: flex;
  flex-direction: row;
}

.tab {
  border: solid 1px #DEE1E6;
  border-right: none;
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none !important;
  transition: background-color 0.2s, color 0.2s;
}

.tab:hover .tab-label, .tab:hover .tab-icon {
  opacity: 0.7;
  color: #0063FF;
}

.tab:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tab:last-of-type {
  border-right: solid 1px #DEE1E6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tab_small {
  padding: 0 10px;
  font-size: 12px;
  height: 32px;
}

.tab.tab_selected {
  border: solid 1px #FFF;
  background-color: #FFF;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .08), 0 1px 3px rgba(0, 0, 0, .04);
  height: 50px;
}

.tab_small.tab_selected {
  height: 32px;
}

.tab_selected .tab-icon {
  color: #0063FF;
  opacity: 1 !important;
}

.tab_selected .tab-label {
  opacity: 0.7 !important;
  font-weight: bold;
  color: #1B2437 !important;
}

.tab_selected + .tab {
  border-left: none;
}

.tab-icon {
  margin-right: 10px;
  opacity: 0.4;
}

.tab-label {
  opacity: 0.6;
  font-weight: normal;
}
