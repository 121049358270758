/* 
 * IcoMoon
 * To see icons, go to https://icomoon.io/app/#/select and upload the icomoon.svg file.
 */

@font-face {
  font-family: 'icomoon';
  src: url('./icomoon.eot?4bnfi4');
  src: url('./icomoon.eot?4bnfi4#iefix') format('embedded-opentype'),
  url('./icomoon.ttf?4bnfi4') format('truetype'),
  url('./icomoon.woff?4bnfi4') format('woff'),
  url('./icomoon.svg?4bnfi4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ic-"], [class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.ic-file:before {
  content: "\e941";
}

.ic-crane:before {
  content: "\e9c6";
}

.ic-container:before {
  content: "\e9c7";
}

.ic-tennis:before {
  content: "\e9c8";
}

.ic-sketch:before {
  content: "\e9c9";
}

.ic-test-quiz:before {
  content: "\e9ca";
}

.ic-education-quiz:before {
  content: "\e9cb";
}

.ic-test-tube:before {
  content: "\e9cc";
}

.ic-test:before {
  content: "\e9cd";
}

.ic-test-1:before {
  content: "\e9ce";
}

.ic-logo-white:before {
  content: "\e902";
  color: #fff;
}

.ic-reacteevText:before {
  content: "\e903";
}

.ic-logo:before {
  content: "\e904";
  color: #0063ff;
}

.ic-rocket:before {
  content: "\e906";
}

.ic-ask:before {
  content: "\e9cf";
}

.ic-question:before {
  content: "\e9cf";
}

.ic-doubt:before {
  content: "\e9cf";
}

.ic-stats:before {
  content: "\e9c4";
}

.ic-pacman:before {
  content: "\e9d0";
}

.ic-game:before {
  content: "\e9d0";
}

.ic-dollar:before {
  content: "\e905";
}

.ic-bill:before {
  content: "\e905";
}

.ic-money:before {
  content: "\e905";
}

.ic-stack:before {
  content: "\e905";
}

.ic-dollar2:before {
  content: "\e9b9";
}

.ic-bill2:before {
  content: "\e9b9";
}

.ic-money2:before {
  content: "\e9b9";
}

.ic-table-tennis:before {
  content: "\e9d1";
}

.ic-document-file-app:before {
  content: "\e9a3";
}

.ic-document-file-png:before {
  content: "\e9a4";
}

.ic-document-file-pdf:before {
  content: "\e9a5";
}

.ic-document-file-mp3:before {
  content: "\e9a6";
}

.ic-document-file-mp4:before {
  content: "\e9a7";
}

.ic-document-file-mov:before {
  content: "\e9a8";
}

.ic-document-file-jpeg:before, .ic-document-file-jpg:before {
  content: "\e9a9";
}

.ic-document-file-html:before, ic-document-file-htm:before {
  content: "\e9aa";
}

.ic-document-file-psd:before {
  content: "\e9ab";
}

.ic-document-file-ai:before {
  content: "\e9ac";
}

.ic-document-file-bmp:before {
  content: "\e9ad";
}

.ic-document-file-eps:before {
  content: "\e9ae";
}

.ic-document-file-tiff:before {
  content: "\e9af";
}

.ic-document-file-doc:before {
  content: "\e9b0";
}

.ic-document-file-xls:before, .ic-document-file-xlsx:before {
  content: "\e9b1";
}

.ic-document-file-docx:before {
  content: "\e9b2";
}

.ic-document-file-ppt:before, .ic-document-file-pptx:before {
  content: "\e9b3";
}

.ic-document-file-xml:before {
  content: "\e9b4";
}

.ic-document-file-mpg:before {
  content: "\e9b5";
}

.ic-document-file-zip:before {
  content: "\e9b6";
}

.ic-document-file-gif:before {
  content: "\e9b7";
}

.ic-paperplane:before {
  content: "\e9d2";
}

.ic-send:before {
  content: "\e9d2";
}

.ic-flight:before {
  content: "\e9d2";
}

.ic-email:before {
  content: "\e9d2";
}

.ic-lab:before {
  content: "\e907";
}

.ic-book2:before {
  content: "\e9d3";
}

.ic-store:before {
  content: "\e9ba";
}

.ic-heart2:before {
  content: "\e9bb";
}

.ic-chart:before {
  content: "\e9bc";
}

.ic-home2:before {
  content: "\e9bd";
}

.ic-trophy:before {
  content: "\e9be";
}

.ic-dashboard3:before {
  content: "\e900";
}

.ic-art_track:before {
  content: "\e060";
}

.ic-poll:before {
  content: "\e801";
}

.ic-assignment:before {
  content: "\e85d";
}

.ic-assignment_ind:before {
  content: "\e85e";
}

.ic-assignment_late:before {
  content: "\e85f";
}

.ic-assignment_turned_in:before {
  content: "\e862";
}

.ic-class:before {
  content: "\e86e";
}

.ic-turned_in_not:before {
  content: "\e8e7";
}

.ic-border_all:before {
  content: "\e228";
}

.ic-burst_mode:before {
  content: "\e43c";
}

.ic-card_travel:before {
  content: "\e8f8";
}

.ic-check_circle:before {
  content: "\e86c";
}

.ic-photo_library:before {
  content: "\e413";
}

.ic-dashboard:before {
  content: "\e871";
}

.ic-done_all:before {
  content: "\e877";
}

.ic-insert_invitation:before {
  content: "\e24f";
}

.ic-event_available:before {
  content: "\e614";
}

.ic-event_busy:before {
  content: "\e615";
}

.ic-event_note:before {
  content: "\e616";
}

.ic-face:before {
  content: "\e87c";
}

.ic-find_in_page:before {
  content: "\e880";
}

.ic-games:before {
  content: "\e021";
}

.ic-import_contacts:before {
  content: "\e0e0";
}

.ic-library_books:before {
  content: "\e02f";
}

.ic-local_library:before {
  content: "\e54b";
}

.ic-looks_3:before {
  content: "\e3fb";
}

.ic-looks_4:before {
  content: "\e3fd";
}

.ic-looks_5:before {
  content: "\e3fe";
}

.ic-looks_6:before {
  content: "\e3ff";
}

.ic-looks_one:before {
  content: "\e400";
}

.ic-looks_two:before {
  content: "\e401";
}

.ic-power_settings_new:before {
  content: "\e8ac";
}

.ic-school:before {
  content: "\e80c";
}

.ic-sentiment_dissatisfied:before {
  content: "\e811";
}

.ic-sentiment_neutral:before {
  content: "\e812";
}

.ic-sentiment_satisfied:before {
  content: "\e813";
}

.ic-sentiment_very_dissatisfied:before {
  content: "\e814";
}

.ic-sentiment_very_satisfied:before {
  content: "\e815";
}

.ic-show_chart2:before {
  content: "\e6e1";
}

.ic-star_border:before {
  content: "\e83a";
}

.ic-star_half:before {
  content: "\e839";
}

.ic-stars:before {
  content: "\e8d0";
}

.ic-store_mall_directory:before {
  content: "\e563";
}

.ic-thumb_down:before {
  content: "\e8db";
}

.ic-thumb_up:before {
  content: "\e8dc";
}

.ic-thumbs_up_down:before {
  content: "\e8dd";
}

.ic-timelapse:before {
  content: "\e422";
}

.ic-videogame_asset:before {
  content: "\e338";
}

.ic-view_column:before {
  content: "\e8ec";
}

.ic-vpn_key:before {
  content: "\e0da";
}

.ic-wb_sunny:before {
  content: "\e430";
}

.ic-whatshot:before {
  content: "\e80e";
}

.ic-do_not_disturb_alt:before {
  content: "\e611";
}

.ic-activity:before {
  content: "\e908";
}

.ic-alert-circle:before {
  content: "\e909";
}

.ic-alert-octagon:before {
  content: "\e90a";
}

.ic-alert-triangle:before {
  content: "\e90b";
}

.ic-anchor:before {
  content: "\e90c";
}

.ic-archive:before {
  content: "\e90d";
}

.ic-arrow-down:before {
  content: "\e90e";
}

.ic-arrow-down-circle:before {
  content: "\e90f";
}

.ic-arrow-down-left:before {
  content: "\e910";
}

.ic-arrow-down-right:before {
  content: "\e911";
}

.ic-arrow-left:before {
  content: "\e912";
}

.ic-arrow-left-circle:before {
  content: "\e913";
}

.ic-arrow-right:before {
  content: "\e914";
}

.ic-arrow-right-circle:before {
  content: "\e915";
}

.ic-arrow-up:before {
  content: "\e916";
}

.ic-arrow-up-circle:before {
  content: "\e917";
}

.ic-arrow-up-left:before {
  content: "\e918";
}

.ic-arrow-up-right:before {
  content: "\e919";
}

.ic-at-sign:before {
  content: "\e91a";
}

.ic-award:before {
  content: "\e91b";
}

.ic-bar-chart-2:before {
  content: "\e91c";
}

.ic-bell:before {
  content: "\e91d";
}

.ic-bell-off:before {
  content: "\e91e";
}

.ic-book:before {
  content: "\e91f";
}

.ic-book-open:before {
  content: "\e920";
}

.ic-bookmark:before {
  content: "\e921";
}

.ic-box:before {
  content: "\e922";
}

.ic-zbriefcase:before {
  content: "\e923";
}

.ic-calendar:before {
  content: "\e924";
}

.ic-check:before {
  content: "\e925";
}

.ic-check-circle:before {
  content: "\e926";
}

.ic-check-square:before {
  content: "\e927";
}

.ic-chevron-down:before {
  content: "\e928";
}

.ic-chevron-left:before {
  content: "\e929";
}

.ic-chevron-right:before {
  content: "\e92a";
}

.ic-chevron-up:before {
  content: "\e92b";
}

.ic-circle:before {
  content: "\e92c";
}

.ic-clipboard:before {
  content: "\e92d";
}

.ic-clock:before {
  content: "\e92e";
}

.ic-cloud-drizzle:before {
  content: "\e99e";
}

.ic-code:before {
  content: "\e92f";
}

.ic-codepen:before {
  content: "\e930";
}

.ic-compass:before {
  content: "\e931";
}

.ic-copy:before {
  content: "\e932";
}

.ic-cpu:before {
  content: "\e933";
}

.ic-scrosshair:before {
  content: "\e934";
}

.ic-database:before {
  content: "\e935";
}

.ic-delete:before {
  content: "\e936";
}

.ic-disc:before {
  content: "\e937";
}

.ic-dollar-sign:before {
  content: "\e938";
}

.ic-download:before {
  content: "\e939";
}

.ic-download-cloud:before {
  content: "\e93a";
}

.ic-edit:before {
  content: "\e93b";
}

.ic-edit-2:before {
  content: "\e93c";
}

.ic-edit-3:before {
  content: "\e93d";
}

.ic-external-link:before {
  content: "\e93e";
}

.ic-eye:before {
  content: "\e93f";
}

.ic-eye-off:before {
  content: "\e940";
}

.ic-file-minus:before {
  content: "\e942";
}

.ic-file-plus:before {
  content: "\e943";
}

.ic-file-text:before {
  content: "\e944";
}

.ic-filter:before {
  content: "\e945";
}

.ic-folder:before {
  content: "\e946";
}

.ic-folder-minus:before {
  content: "\e947";
}

.ic-folder-plus:before {
  content: "\e948";
}

.ic-gift:before {
  content: "\e949";
}

.ic-globe:before {
  content: "\e94a";
}

.ic-grid:before {
  content: "\e94b";
}

.ic-hard-drive:before {
  content: "\e94c";
}

.ic-hash:before {
  content: "\e94d";
}

.ic-heart:before {
  content: "\e94e";
}

.ic-help-circle:before {
  content: "\e94f";
}

.ic-home:before {
  content: "\e950";
}

.ic-image:before {
  content: "\e951";
}

.ic-inbox:before {
  content: "\e952";
}

.ic-info:before {
  content: "\e953";
}

.ic-layers:before {
  content: "\e954";
}

.ic-layout:before {
  content: "\e955";
}

.ic-life-buoy:before {
  content: "\e956";
}

.ic-link:before {
  content: "\e957";
}

.ic-list:before {
  content: "\e958";
}

.ic-lock:before {
  content: "\e959";
}

.ic-log-in:before {
  content: "\e95a";
}

.ic-log-out:before {
  content: "\e95b";
}

.ic-mail:before {
  content: "\e95c";
}

.ic-map:before {
  content: "\e95d";
}

.ic-map-pin:before {
  content: "\e95e";
}

.ic-menu:before {
  content: "\e95f";
}

.ic-message-circle:before {
  content: "\e960";
}

.ic-message-square:before {
  content: "\e961";
}

.ic-minus-circle:before {
  content: "\e962";
}

.ic-minus-square:before {
  content: "\e963";
}

.ic-monitor:before {
  content: "\e964";
}

.ic-more-horizontal:before {
  content: "\e965";
}

.ic-more-vertical:before {
  content: "\e966";
}

.ic-navigation:before {
  content: "\e967";
}

.ic-package:before {
  content: "\e968";
}

.ic-paperclip:before {
  content: "\e969";
}

.ic-pause-circle:before {
  content: "\e96a";
}

.ic-pie-chart:before {
  content: "\e96b";
}

.ic-plus:before {
  content: "\e96c";
}

.ic-plus-circle:before {
  content: "\e96d";
}

.ic-plus-square:before {
  content: "\e96e";
}

.ic-power:before {
  content: "\e96f";
}

.ic-printer:before {
  content: "\e970";
}

.ic-radio:before {
  content: "\e971";
}

.ic-refresh-cw:before {
  content: "\e972";
}

.ic-repeat:before {
  content: "\e973";
}

.ic-rotate-ccw:before {
  content: "\e974";
}

.ic-rss:before {
  content: "\e975";
}

.ic-save:before {
  content: "\e976";
}

.ic-search:before {
  content: "\e977";
}

.ic-send2:before {
  content: "\e9d4";
}

.ic-server:before {
  content: "\e978";
}

.ic-settings:before {
  content: "\e979";
}

.ic-share-2:before {
  content: "\e97a";
}

.ic-shopping-bag:before {
  content: "\e97b";
}

.ic-slash:before {
  content: "\e99f";
}

.ic-sliders:before {
  content: "\e97c";
}

.ic-smartphone:before {
  content: "\e97d";
}

.ic-speaker:before {
  content: "\e97e";
}

.ic-square:before {
  content: "\e97f";
}

.ic-star:before {
  content: "\e980";
}

.ic-sun:before {
  content: "\e9a0";
}

.ic-tablet:before {
  content: "\e981";
}

.ic-tag:before {
  content: "\e982";
}

.ic-target:before {
  content: "\e983";
}

.ic-thumbs-down:before {
  content: "\e984";
}

.ic-thumbs-up:before {
  content: "\e985";
}

.ic-trash:before {
  content: "\e986";
}

.ic-trash-2:before {
  content: "\e987";
}

.ic-trending-up:before {
  content: "\e988";
}

.ic-truck:before {
  content: "\e989";
}

.ic-tv:before {
  content: "\e9a1";
}

.ic-umbrella:before {
  content: "\e9a2";
}

.ic-unlock:before {
  content: "\e98a";
}

.ic-upload:before {
  content: "\e98b";
}

.ic-upload-cloud:before {
  content: "\e98c";
}

.ic-user:before {
  content: "\e98d";
}

.ic-user-check:before {
  content: "\e98e";
}

.ic-user-minus:before {
  content: "\e98f";
}

.ic-user-plus:before {
  content: "\e990";
}

.ic-user-x:before {
  content: "\e991";
}

.ic-users:before {
  content: "\e992";
}

.ic-x:before {
  content: "\e993";
}

.ic-x-circle:before {
  content: "\e994";
}

.ic-x-square:before {
  content: "\e995";
}

.ic-zap:before {
  content: "\e996";
}

.ic-picture_as_pdf:before {
  content: "\e9b8";
}

.ic-show_chart:before {
  content: "\e997";
}

.ic-playlist_add_check:before {
  content: "\e998";
}

.ic-network_check:before {
  content: "\e999";
}

.ic-lightbulb_outline:before {
  content: "\e99a";
}

.ic-gamepad:before {
  content: "\e99b";
}

.ic-line-graph:before {
  content: "\e9c5";
}

.ic-emoji-sad:before {
  content: "\e9bf";
}

.ic-emoji-happy:before {
  content: "\e9c0";
}

.ic-lab-flask:before {
  content: "\e9c1";
}

.ic-emoji-sad2:before {
  content: "\e99c";
}

.ic-emoji-happy2:before {
  content: "\e99d";
}

.ic-currency-dollar:before {
  content: "\e9c2";
}

.ic-factory:before {
  content: "\e9c3";
}

.ic-dashboard2:before {
  content: "\e901";
}
