/* Create two equal columns that floats next to each other */
.results-column {
  float: left;
  width: 50%;
  padding: 10px;
}

.results-column-left {
  width: 67%;
}
.results-column-right {
  width: 33%;
}

/* Clear floats after the columns */
.results-row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 820px) {
  .results-column {
    width: 100% !important;
  }
}
