.d-flex {
  display: flex !important;
}

.flex_1 {
  flex: 1 1 auto;
}

.flexDirection_row {
  flex-direction: row !important;
}

.flexDirection_column {
  flex-direction: column !important;
}

.alignItems_center {
  align-items: center !important;
}

.justifyContent_center {
  justify-content: center !important;
}

.justifyContent_flexEnd {
  justify-content: flex-end !important;
}

.justifyContent_flexStart {
  justify-content: flex-start !important;
}
