.groups-block-btnTrash {
  position: absolute;
  top: 15px;
  right: 10px;
  display: none;
}

.adminPanel-block-content-line.group-block {
  position: relative;
}

.adminPanel-block-content-line.group-block:hover .groups-block-btnTrash {
  display: block;
}

.group-details {
  flex: 1 1;
}

.group-details .members-block {
  margin-bottom: 0;
}

.group-details .members-block-content {
  margin-bottom: 0;
}

.members-collapse {
  cursor: pointer;
}
