.board-column {
  flex: 1;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .08), 0 1px 3px rgba(0, 0, 0, .04);
  overflow: visible;
  z-index: auto;
  position: static;
  margin: 15px 20px 15px 0;
  min-width: 240px;
}

.board-column:first-of-type {
  margin-left: 0;
}

.board-column:last-of-type {
  margin-right: 0;
}

.board-column-cards {
  flex: 1;
}
