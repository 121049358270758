.funnel-container {
  display: flex;
  background-color: #FFF;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .08), 0 1px 3px rgba(0, 0, 0, .04);
  padding: 20px;
  flex-wrap: wrap;
}
.funnel-block {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  justify-content: center;
  padding-top: 50px;
}
.funnel-block.border {
  border-right: 1px solid #9AA2AB;
}
.funnel-stage-header {
  position: absolute;
  top: 0;
  text-align: center;
}
.funnel-stage-container {
}
.funnel-stage-item {
  text-align: center;
  margin: 5px;
  padding: 5px;
  border: 1px solid #9AA2AB;
}
.funnel-separator {
  border: 1px solid #9AA2AB;
  flex-grow: 1;
}
