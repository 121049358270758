.searchPanel-group {
  border-top: solid 1px #EBEDF0;
  border-bottom: solid 1px #EBEDF0;
}

.searchPanel-searched {
  margin-bottom: 55px;
}

.searchPanel-searched-icon {
  margin-right: 10px;
}

.searchPanel-searched-text {
  color: #0063FF;
}

.searchPanel-title {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
}

.searchPanel-block {
  margin: 0 0 50px;
}

.searchPanel-title-icon {
  font-size: 24px;
  margin-right: 10px;
}

/* Projects */
.searchPanel-title-icon.ic-folder {
  color: #F1755A;
}
.searchPanel-title-icon.ic-folder + .searchPanel-title-count {
  background-color: #F1755A;
}

/* Ideas */
.searchPanel-title-icon.ic-lightbulb_outline {
  color: #FDD760;
}
.searchPanel-title-icon.ic-lightbulb_outline + .searchPanel-title-count {
  background-color: #FDD760;
}

.searchPanel-title-icon.ic-check-circle {
  color: #B4F9BC;
}

/* Experiments */
.searchPanel-title-icon.ic-rocket {
  color: #7795F8;
}
.searchPanel-title-icon.ic-rocket + .searchPanel-title-count {
  background-color: #7795F8;
}

/* Learnings */
.searchPanel-title-icon.ic-book-open, .searchPanel-line-icon.ic-book {
  color: #6BD5F8;
}
.searchPanel-title-icon.ic-book-open + .searchPanel-title-count {
  background-color: #6BD5F8;
}

/* Actions */
.searchPanel-title-icon.ic-education-quiz {
  color: #38c548;
}
.searchPanel-title-icon.ic-education-quiz + .searchPanel-title-count {
  background-color: #38c548;
}

/* Files */
.searchPanel-title-icon.ic-paperclip, .searchPanel-line-icon.ic-paperclip {
  color: #0063FF;
}
.searchPanel-title-icon.ic-paperclip + .searchPanel-title-count {
  background-color: #0063FF;
}

.searchPanel-title-text {
  flex: 1;
}

.searchPanel-title-count {
  color: #FFF;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  margin-right: 15px;
}

.searchPanel-title-filters {
  font-size: 18px;
  opacity: 0.6;
  cursor: pointer;
  font-weight: normal;
}

.searchPanel-title-filters-icon {
  margin-right: 10px;
}

.searchPanel-block {
  padding: 0;
}

.searchPanel-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  border-bottom: solid 1px #EBEDF0;
  flex-wrap: wrap;
}

.searchPanel-line-icon {
  font-size: 26px;
  margin-right: 10px;
}

.searchPanel-line-text {
  flex: 1;
  margin-bottom: 5px;
  margin-right: 5px;
}

.searchPanel-line-infos {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-bottom: 5px;
}

.searchPanel-line-info {
  margin-right: 25px;
  width: 35px;
  text-align: center;
}

.searchPanel-line-info-icon {
  margin-right: 5px;
}

.searchPanel-line-btn {
  font-size: 18px;
}

.searchPanel-line .dropdown {
  margin-right: 20px;
  padding: 7px 12px 6px;
  font-size: 14px;
  margin-bottom: 0;
}
