.coverflow-container {
  position: relative;
  /*background: rgba(0, 0, 0, .1);*/
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}

.coverflow {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
}

.stage {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  transform-style: preserve-3d;
  perspective: 500px;
}

.figure {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  flex: 0 0 auto;
  cursor: pointer;
  transition: transform 600ms ease;
  backface-visibility: hidden;
  z-index: 9;
  align-self: center;
  box-shadow: 0 50px 70px rgba(0, 0, 0, 0.5);
}

.cover {
  display: block;
  width: 100%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .5);
}

.preloader {
  display: none;
}

.text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: .7em;
  color: white;
  padding: 5px;
  overflow: hidden;
  background: rgba(0, 0, 0, .6);
}

@media (max-width: 480px) {
  .text {
    display: none;
  }
}

.actions {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.actions.button {
  border: 1px solid #616161;
  border-radius: 2px;
  min-width: 120px;
  padding: 5px;
  margin: 2px;
  background: #616161;
  color: rgba(255, 255, 255, .9);
}

.actions.button:hover {
  background: black;
  color: white;
}

.actions.button:focus {
  outline: none;
}

@keyframes prevent {
  0% {
    pointer-events: none;
  }
  100% {
    pointer-events: auto;
  }
}
