.board-ideaCard {
  border-radius: 5px;
  border: solid 1px #DEE1E6;
  margin-bottom: 15px;
  cursor: pointer;
  background: #FFF;
  position: relative;
  overflow: visible;
}

.board-ideaCard:hover .boardIdeaCard-btnTrash {
  display: block;
}

.board-ideaCard:last-of-type {
  margin-bottom: 0;
}

.board-ideaCard-title {
  padding: 15px;
  cursor: pointer;
}

.board-ideaCard-experiments {
  padding: 0 15px 0 25px;
}

.board-ideaCard-experiments-header {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  opacity: 0.8;
  margin-bottom: 15px;
}

.board-ideaCard-experiments-header-text {
  margin-left: 5px;
  flex: 1;
}

.board-ideaCard-experiments-content {
  margin-top: -8px;
}

.board-ideaCard-experiment {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 8px 0;
  border-top: solid 1px rgba(222, 225, 230, 0.48);
  align-items: center;
}

.board-ideaCard-experiment:last-of-type {
  padding-bottom: 15px;
}

.board-ideaCard-experiment-state {
  margin-right: 8px;
  transform: translateY(1px);
  font-size: 16px;
}

.board-ideaCard-experiment-text {
  flex: 1;
}

.board-ideaCard-experiment-cta {
  font-size: 18px;
}

.board-ideaCard-experiments-header-count {
  background-color: #7795F8;
  color: #FFF;
  width: 16px;
  height: 16px;
  display: inline-block;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  margin: 0 5px;
}

.boardIdeaCard-btnTrash {
  position: absolute;
  top: -8px;
  right: -8px;
  display: none;
}
