.experimentCard > .validation-form {
  display: flex;
  flex-direction: column;
}

.experimentCard > .validation-form > * {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto 15px;
}

.experimentCard > .validation-form > .validation-status {
  flex-direction: column;
}

.experimentCard .validation-actions > * {
  margin: auto
}

.experimentCard > .validation-form .btn_primary {
  display: block;
  width: fit-content;
  border-width: 0;
}

.user-avatar {
  width: 35px;
  border-radius: 5px;
  margin-left: 20px;
  vertical-align: middle;
}
