.board-header {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 40px;
  flex-shrink: 0;
}

.board-header-left-icon-wrapper {
  align-self: stretch;
  height: 52px;
  width: 52px;
  border-radius: 5px;
  margin-right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .08), 0 1px 3px rgba(0, 0, 0, .04);
  font-size: 28px;
  color: #FFF;
  transition: background-color 0.2s;
  will-change: background-color;
  flex-shrink: 0;
}

.board-header-left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.board-header-title {
  margin-top: 0;
  margin-bottom: 8px;
  margin-right: 25px;
}

.board-header-infos {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.board-header-info {
  margin-right: 35px;
}

.board-header-info-icon {
  margin-right: 5px;
}

@media(max-width: 1200px) {
  .board-header .tab-icon {
    margin: 0;
  }

  .board-header .tab-label {
    display: none;
  }
}
