.members-block {
  flex: 1;
  margin-bottom: 25px;
}

.member-avatar {
  width: 35px;
  border-radius: 5px;
  margin-right: 20px;
  vertical-align: middle;
}

.member-thumbnail {
  width: 20px;
  border-radius: 2px;
  margin-left: 3px;
  vertical-align: middle;
}

.members-block-content {
  padding: 0;
  margin: 15px 0;
  min-height: 250px;
  overflow: visible;
  position: relative;
}

.members-block-content-line:hover .members-block-btnTrash {
  display: block;
}

.members-block-content-line {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  border-bottom: solid 1px #EBEDF0;
  align-items: center;
  position: relative;
  height: 50px;
}

.members-block-content-line .switch {
  margin-right: 15px;
}

.members-block-content-line-icon {
  font-size: 20px;
  width: 20px;
  height: auto;
  vertical-align: middle;
}

img.members-block-content-line-icon:hover {
  width: 25%;
  margin-right: -270px;
  position: relative;
  z-index: 2;
}

.members-block-content-line-text {
  flex: 1;
}

.members-block-content-line-text span {
  vertical-align: middle;
}

.members-block-btnTrash {
  position: absolute;
  top: 7px;
  right: -10px;
  display: none;
}

.members-block-form {
  background: #FFF;
}

.member-dropdown-item {
  cursor: pointer;
  padding: 10px;
  border-top: 1px solid lightgray;
}

.member-dropdown-item.highlighted, .member-dropdown-item:hover {
  background-color: lightgray;
}

.member-dropdown-item:first-of-type {
  padding: 10px;
  border-top: 0;
}
