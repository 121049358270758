.ideaModal-btnTrash {
  display: none;
}

.ideaModal .modal-title {
  height: 51px;
}

.ideaModal .modal-title h2:hover .ideaModal-btnTrash {
  display: block;
  margin-left: 10px;
}
