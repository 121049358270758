.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: #FFF;
  border-radius: 5px;
  padding: 20px;
  min-width: 300px;
  max-width: 100%;
}

.modal-title {
  margin-top: 5px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: solid 1px #1b24375c;
  min-height: 50px;
}

.modal-title h2 {
  display: flex;
  align-items: center;
}

.modal-back {
  cursor: pointer;
  opacity: 0.4;
  margin-bottom: 20px;
  transition: opacity 0.3s;
  font-size: 14px;
}

.modal-back:hover {
  opacity: 1;
}

.drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 20%;
  border-radius: 0;
  padding: 30px 20px 50px;
  background-color: #FFF;
  overflow-y: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
}

@media(max-width: 1200px) {
  .drawer {
    right: 8%;
  }
}

@media(max-width: 820px) {
  .drawer {
    right: 0;
  }
}
