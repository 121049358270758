.card {
  background-color: #FFF;
  margin: 15px 30px 15px 0;
  z-index: 499;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 40px 40px 40px 120px;
  border-radius: 4px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
  transition-property: color, background-color, box-shadow, transform, -webkit-transform;
  transition-duration: .15s;
}
