.imagePicker {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*padding: 0 15px;*/
  /*border-bottom: solid 1px #EBEDF0;*/
  /*align-items: center;*/
  position: relative;
  /*height: 50px;*/
}

.imagePicker-btnTrash {
  position: absolute;
  top: 7px;
  right: -10px;
  display: none;
}

.imagePicker:hover .imagePicker-btnTrash {
  display: block;
}
