.file-block-metadata {
  margin-right: 20px
}

.file-block-metadata {
  display: flex;
}
.file-block-metadata select {
  margin-right: 8px;
}
