.users > .adminPanel-users > .members-block-content {
  margin: 0;
}

.users .form-group .input.error {
  border-color: #e62700;
}

.users .form-group .text_small.error {
  color: #e62700;
}

.users .members-block-btnTrash {
  right: 10px;
}
