.public-gallery {
  margin: 20px;
}

.public-gallery-header {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header-organization-logo {
  height: 60px;
  width: auto;
}

.public-gallery-search-engine {
  margin-bottom: 8px;
}
.public-gallery-search-engine .ic-search {
  font-size: 24px;
}
.public-gallery-search-input {
  border: none;
  outline: none;
  font-size: 24px;
  font-style: italic;
  margin: 0 8px;
  padding: 2px;
}
.public-gallery-social-network a {
  margin-left: 8px;
}
.public-gallery-social-network img {
  height: 36px;
}

.public-gallery-no-project {
  padding: 16px;
  margin-top: 12px
}

.public-gallery-files {
  background-color: white;
  box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
  margin-top: 24px;
}
.public-gallery-file {
  margin: 0 24px;
  padding-top: 24px;
}

.public-gallery-file-icon {
  position: absolute;
  font-size: 64px;
}
.public-gallery-file-icon.ic-folder {
  color: #F1755A;
}
.public-gallery-file-icon.ic-lightbulb_outline {
  color: #FDD760;
}
.public-gallery-file-icon.ic-check-circle {
  color: #B4F9BC;
}
.public-gallery-file-icon.ic-rocket {
  color: #7795F8;
}
.public-gallery-file-icon.ic-book {
  color: #6BD5F8;
}
.public-gallery-file-cta {
  min-height: 80px;
  margin-left: 80px;
  padding-bottom: 16px;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.public-gallery-last-file {
  border-bottom: none;
}

.public-gallery-footer {
  margin-top: 32px;
  border-top: 4px solid #0063FF;
  padding: 8px;
  color: grey;
  position: relative;
}

.public-gallery-footer-logo {
  font-size: 36px;
  position: absolute;
  right: 0;
  top: -20px;
  background-color: #F3F7FA;
}
