.header-organization-dropdown_wrapper {
  position: relative;
}

.header-organization-dropdown-label {
  border-radius: 3px;
  padding: 6px 12px 4px;
}

.header-organization-dropdown-label > i {
  float: right;
}

.header-organization-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: 33px;
  border-radius: 4px;
  -webkit-box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
  box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
  background-color: #FFF;
  z-index: 100000;
}

.header-organization-dropdown-item {
  padding: 7px 10px;
  border-bottom: solid 1px #EEE;
  cursor: pointer;
}

.header-organization-dropdown-item:hover {
  background-color: #EEE;
  color: #7A828B;
}
