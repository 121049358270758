.boardBusinessChallenge {
  padding-top: 1em;
}

@media(max-width: 824px) {
  .boardBusinessChallenge .boardBusinessChallenge-canvasBlock-resources {
    border-top: none;
    border-bottom-left-radius: 5px;
    border-left-width: 2px;
  }

  .boardBusinessChallenge-canvasBlock-context {
    border-bottom-left-radius: 0 !important;
  }
}
