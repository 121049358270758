.p-r-15 {
  padding-right: 15px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-20 {
  padding: 20px !important;
}
