.canvas-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.canvasBlock {
  border: solid #DEE1E6 0;
  border-top-width: 2px;
  border-right-width: 2px;
  padding: 20px;
  background-color: #FFF;
  flex: 1;
  transition: all 0.5s ease;
  min-height: 205px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
}

.canvasBlock:hover {
  border: 2px solid rgba(0, 99, 255, 0.56);
  box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
  z-index: 499;
}

.canvasBlock-title {
  text-transform: capitalize;
  font-size: 24px;
}

.canvasBlock-title-icon {
  margin-right: 10px;
}

.canvas-line .canvasBlock:first-of-type {
  border-left-width: 2px;
}

.canvas-line:first-of-type .canvasBlock:first-of-type {
  border-top-left-radius: 5px;
}

.canvas-line:first-of-type .canvasBlock:last-of-type {
  border-top-right-radius: 5px;
}

.canvas-line:last-of-type .canvasBlock {
  border-bottom-width: 2px;
}

.canvas-line:last-of-type .canvasBlock:first-of-type {
  border-bottom-left-radius: 5px;
}

.canvas-line:last-of-type .canvasBlock:last-of-type {
  border-bottom-right-radius: 5px;
}

.canvasBlock-textarea {
  flex: 1;
  border: none !important;
  box-shadow: none !important;
  padding-left: 0;
  padding-right: 0;
  resize: vertical;
}

@media(max-width: 720px) {
  .canvas-line {
    display: block;
  }

  .canvas-line .canvasBlock {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 2px;
    border-right-width: 2px;
    border-radius: 0 !important;
  }

  .canvas-line:first-of-type .canvasBlock:first-of-type {
    border-top-width: 2px;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }

  .canvas-line:last-of-type .canvasBlock:last-of-type {
    border-bottom-width: 2px;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
}
