.panel {
  padding: 30px 60px;
  height: calc(100% - 78px);
  overflow-y: scroll; /* has to be scroll, not auto, to fix iOS bug */
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
}

@media(max-width: 1200px) {
  .panel {
    padding-left: 30px;
    padding-right: 30px;
  }
}
