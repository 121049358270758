.files-block {
  flex: 1;
  margin-bottom: 25px;
}

.files-block .dropzone {
  padding: 30px;
  margin-bottom: -104px;
}

.files-block .dropzone.visible {
  margin-bottom: 15px;
}

.files-block-content {
  padding: 0;
  margin: 0 0 15px;
  min-height: 250px;
  overflow: visible;
}

.files-block-content-line:hover .files-block-btnTrash {
  display: block;
}

.files-block-content-line {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  border-bottom: solid 1px #EBEDF0;
  align-items: center;
  position: relative;
  height: 50px;
}

.files-block-content-line-icon {
  font-size: 20px;
  width: 20px;
  height: auto;
  vertical-align: middle;
  transition-property: width, margin, background-color, box-shadow;
  transition-duration: .15s;
}

img.files-block-content-line-icon:hover {
  width: 25%;
  margin-right: -270px;
  position: relative;
  z-index: 2;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
}

.files-block-content-line-text {
  flex: 1;
  color: inherit;
  text-decoration: none;
}

.files-block-content-line-text span {
  position: absolute;
  left: 50px;
  top: 15px;
}

.files-block-btnTrash {
  position: absolute;
  top: 7px;
  right: -10px;
  display: none;
}

.files-title-icon {
  margin-right: 10px;
}
