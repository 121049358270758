.actionModal-btnTrash {
  display: none;
}

.actionModal .modal-title {
  height: 51px;
}

.actionModal .modal-title h2:hover .actionModal-btnTrash {
  display: block;
  margin-left: 10px;
}
