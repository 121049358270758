.feedback-wrapper {
  width: 100%;
}

.feedback {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.feedback.error {
  background-color: red;
}

.feedback.success {
  background-color: green;
}

.feedback .feedback-label {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  margin: auto;
}
