.board-learnings-blocks {
  display: flex;
  flex-direction: row;
  margin: 0 -15px;
}

.tasks-learnings {
  display: flex;
  min-height: 0;
}

.tasks-learnings .learnings-block-content {
  min-height: 0;
}

.tasks-learnings .searchPanel-group {
  border: none;
}

.board-learnings-blocks .learnings-block:first-of-type {
  margin-right: 15px;
}

.board-learnings-blocks .learnings-block:last-of-type {
  margin-left: 15px;
}

@media(max-width: 1000px) {
  .board-learnings-blocks {
    display: block;
    margin: 0;
  }

  .board-learnings-blocks .learnings-block {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
