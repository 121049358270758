.experimentModal-btnTrash {
  margin-left: 15px;
  transform: translateY(-3px);
  display: none;
}

.experimentModal h2:hover .experimentModal-btnTrash {
  display: inline-block;
}

.experimentCard .canvasBlock {
  min-height: 100px;
}

.experimentCard-canvasBlock-plan {
  min-height: 200px !important;
}

.experimentCard .canvasBlock-title {
  font-size: 16px;
}

.experimentCard-canvasBlock-metrics {
  flex: 1.8;
}

.experimentModal h2 {
  min-width: 300px;
  margin-bottom: 10px !important;
  min-height: 30px;
}

.experimentModal .modal-title {
  flex-wrap: wrap;
  padding-bottom: 0;
}

.experimentModal .experimentStateLabel .dropdown {
  margin-right: 20px;
}

@media(max-width: 430px) {
  .experimentModal .tab-icon {
    margin: 0;
  }

  .experimentModal .tab-label {
    display: none;
  }
}
