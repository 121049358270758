@media (min-width: 768px) {
  .dashboardPanel {
    padding: 30px 60px;
  }
}

.dashboardPanel-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dashboardPanel-title-icon {
  margin-right: 8px;
}

.dashboardPanel-title-text {
  flex: 1;
}

.dashboardPanel-title-filters {
  font-size: 16px;
  opacity: 0.6;
  cursor: pointer;
  font-weight: normal;
}

.dashboardPanel-title-filters-icon {
  margin-right: 8px;
}

.dashboardPanel-stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;
}
@media (min-width: 768px) {
  .dashboardPanel-stats {
    margin: 0 -15px;
  }
}

@media (max-width: 444px) {
  .dashboardPanel-stats {
    margin: 0;
    display: block;
  }

  .statCard {
    min-width: 0 !important;
  }
}

.dashboardPanel-blocks {
  display: flex;
  flex-direction: row;
}

.dashboardPanel-block {
  flex: 1;
  margin-bottom: 55px;
}

.dashboardPanel-blocks .dashboardPanel-block:first-of-type {
  margin-right: 15px;
}

.dashboardPanel-blocks .dashboardPanel-block:last-of-type {
  margin-left: 15px;
}

.dashboardPanel-block-content {
  padding: 0;
  margin: 15px 0;
}

.dashboardPanel-block-content-line {
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  border-bottom: solid 1px #EBEDF0;
  align-items: center;
  text-decoration: none !important;
  color: #1B2437 !important;
}

.dashboardPanel-block-content-line-icon {
  margin-right: 10px;
}

.dashboardPanel-block-content-line-date {
  margin-right: 15px;
  opacity: 0.6;
  font-size: 14px;
}

.dashboardPanel-block-content-line-text {
  flex: 1;
}

.dashboardPanel-block-content-line-icon, .dashboardPanel-block-content-line-cta {
  font-size: 20px;
}

.dashboardPanel-game-line .dashboardPanel-block-content-line-icon {
  font-size: 22px;
}

.member-avatar {
  width: 35px;
  border-radius: 5px;
  margin-right: 20px;
  vertical-align: middle;
}

.dashboardPanel-columns h3 {
  margin-left: 30px;
}

@media (min-width: 992px) {
  .dashboardPanel-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -15px;
  }
  .dashboardPanel-columns > div {
    width: 50%;
    margin: 0 15px;
  }
  .dashboardPanel-columns > div > .dashboardPanel-block-content {
    margin-top: 0;
  }
}
