.with_fitContent {
  width: fit-content !important;
}

.size_fullAbsolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
