.dropzone {
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border-radius: 5px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  padding: 20px;
  line-height: 40px;
  transition: all .30s ease;
  will-change: transform, margin;
  transform: scaleY(0);
  margin-bottom: -84px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.dropzone.visible {
  transform: scaleY(1);
  margin-bottom: 0;
}

.dropzone i {
  font-size: 30px;
  margin-right: 15px;
  vertical-align: middle;
}

.dropzone.active {
  background-color: lightblue;
}

.dropzone.accept {
  background-color: lightgreen;
  border-color: green;
}

.dropzone.reject {
  background-color: red;
}
