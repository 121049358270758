.statCard {
  flex: 1;
  margin: 0 10px 20px;
  min-width: 300px;
  position: relative;
}

@media (min-width: 768px) {
  .statCard {
    margin: 0 15px 30px;
  }
}

a.statCard {
  text-decoration: none;
  color: black;
}
a.statCard:visited {
  color: black;
}

.statCard-icon-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statCard-icon {
  font-size: 64px;
}

.statCard-value {
  font-size: 62px;
  font-weight: bold;
  text-align: right;
}

.statCard-label {
  font-size: 20px;
  text-align: right;
}

.statCard-icon.ic-folder {
  color: #F1755A;
}

.statCard-icon.ic-lightbulb_outline {
  color: #FDD760;
}

.statCard-icon.ic-check-circle {
  color: #B4F9BC;
}

.statCard-icon.ic-rocket {
  color: #7795F8;
}

.statCard-icon.ic-book {
  color: #6BD5F8;
}
