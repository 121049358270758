.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 78px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.07), 0 1px 3px rgba(0, 0, 0, .05);
  background-color: #FFF;
  color: #9AA2AB;
  padding: 0 30px;
}

.header-item {
  text-decoration: none !important;
  color: #9AA2AB !important;
  padding: 0 30px;
  display: flex;
  font-size: 16px;
  align-items: center;
}

.header-item_active {
  color: #0063FF !important;
}

.header-item-icon {
  display: inline-block;
  font-size: 18px;
  margin-right: 12px;
  opacity: 0.7;
}

.header-spacer {
  flex: 1;
}

.header-logo {
  position: absolute;
  left: 50%;
  margin-left: -18px;
  font-size: 36px;
  color: #0063FF;
}

.header-notification {
  position: relative;
}

.header-notification i {
  font-size: 22px;
}

.header-notification-bubble {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -2px;
  right: 42px;
  border-radius: 50%;
  border: solid 3px #0063FF;
}

.header-search {
  font-style: italic;
  opacity: 0.7;
  overflow: hidden;
}

.header-search .header-item-icon {
  opacity: 1;
  margin-right: 12px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s;
  will-change: background;
  padding: 0 6.225px !important;
}

.header-avatar {
  width: 35px;
  border-radius: 5px;
  margin-right: 20px;
}

.header-search-input {
  border: none;
  outline: none;
  font-size: 16px;
  font-style: italic;
}

.header-user-dropdown-button {
  border-width: 0;
  width: 100%;
  display: block;
}

a.header-user-dropdown-button {
  color: #9AA2AB;
  text-decoration: none;
}

a.header-user-dropdown-button:hover {
  background-color: #EEE;
  color: #7A828B;
}

.header-user {
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.header-user-dropdown {
  position: absolute;
  right: 25px;
  padding: 10px;
  top: 39px;
  border-radius: 4px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
  background-color: #FFF;
  z-index: 100000;
}
.header-user-dropdown > div:not(:last-child) {
  margin-bottom: 10px;
}

@media(max-width: 1200px) {
  .header-logo {
    position: static;
    margin-left: 0;
    margin-right: 15px;
  }

  .header-item {
    padding: 0 15px;
  }

  .header-item-label {
    display: none;
  }

  .header-item-icon {
    margin-right: 0;
  }
}

@media(max-width: 700px) {
  .header-user {
    display: none;
  }
}
