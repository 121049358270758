* {
  box-sizing: border-box;
}

html, body, #root, .app {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: Acre, sans-serif;
  color: #1B2437;
  background-color: #F3F7FA;
}

p {
  margin: 0;
}

i {
  font-style: italic;
}
