.boardCard-wrapper {
  width: 33.3%;
}

.boardCard {
  padding: 40px 40px 25px 120px;
  cursor: pointer;
  min-height: 165px;
  height: calc(100% - 30px);
  position: relative;
  overflow: visible;
}

.boardCard-wrapper:nth-child(3n) .boardCard {
  margin-right: 0;
}

.boardCard:hover .boardCard-btnTrash {
  display: block;
}

.boardCard-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.boardCard-subtitle {
  font-size: 14px;
  opacity: 0.6;
  margin-top: -5px;
  margin-bottom: 15px;
}

.boardCard-infos {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 17px;
}

.boardCard-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boardCard-info-icon {
  margin-right: 5px;
  font-size: 20px;
}

.boardCard-info-icon.ic-rocket {
  font-size: 23px;
  transform: translateY(1.5px);
}

.boardCard-state {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boardCard-state-icon {
  font-size: 64px;
}

.boardCard-btnTrash {
  position: absolute;
  top: -10px;
  right: -10px;
  display: none;
}

@media(max-width: 1200px) {
  .boardCard-wrapper {
    width: 50%;
  }

  .boardCard-wrapper .boardCard, .boardCard-wrapper:nth-child(3n) .boardCard  {
    margin-right: 15px;
  }

  .boardCard-wrapper:nth-child(2n) .boardCard {
    margin-right: 0;
  }
}

@media(max-width: 800px) {
  .boardCard-wrapper {
    width: 100%;
  }

  .boardCard-wrapper .boardCard {
    margin-right: 15px !important;
  }
}
