.board-column-header {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.board-column-header-icon {
  font-size: 26px;
}

.board-column-header-title {
  flex: 1;
  font-size: 22px;
  margin-left: 15px;
}

.board-column-header-count {
  font-size: 18px;
  opacity: 0.4;
}

.board-column-header-help {
  cursor: pointer;
  opacity: 0.3;
  font-size: 20px;
  transition: opacity 0.5s;
  will-change: opacity;
  margin-top: 5px;
}

.board-column-header-help:hover {
  opacity: 1;
}
