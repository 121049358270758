.helpPanel {
  padding: 30px 60px;
}

.helpPanel-title-icon {
  margin-right: 10px;
}

.helpPanel-blocks {
  display: flex;
  flex-direction: row;
}

.helpPanel-block {
  flex: 1;
  margin-bottom: 25px;
}

.helpPanel-blocks .helpPanel-block:first-of-type {
  margin-right: 15px;
}

.helpPanel-blocks .helpPanel-block:first-of-type .helpPanel-howTo {
  margin-left: 0;
}

.helpPanel-blocks .helpPanel-block:last-of-type {
  margin-left: 15px;
}

.helpPanel-block-content {
  padding: 0;
  margin: 15px 0;
  display: block;
}

.helpPanel-howTo-text {
  display: flex;
  flex-direction: row;
  padding: 15px 0;
  align-items: center;
}

.helpPanel-howTo {
  padding: 20px;
}

.helpPanel-howTo-title {
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: solid 1px #EBEDF0;
}

.helpPanel-howTo-text-number {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 15px;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
  border: solid 2px;
  font-size: 14px;
  flex-shrink: 0;
  opacity: 0.8;
  color: #0063FF;
}

.helpPanel-headline {
  color: #0063FF;
}

.helpPanel-advice {
  padding: 15px 20px;
  border-bottom: solid 1px #EBEDF0;
}

.helpPanel-advice:last-of-type {
  border: none;
}

.helpPanel-advice-dot {
  width: 8px;
  height: 8px;
  border: solid 2px #0063FF;
  opacity: 0.8;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}

.helpPanel-advice-dot_DO {
  border-color: #3ecf8e;
}

.helpPanel-advice-dot_DONT {
  border-color: red;
}
