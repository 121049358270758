.float_right {
  float: right;
}

.overflowY_auto {
  overflow-y: auto;
}

.cursor_pointer {
  cursor: pointer;
}
